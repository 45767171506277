import React from 'react';

const InstallPopup = ({ handleInstallClick, onClose }) => {
  return (
    <div className="install-popup">
      <div className="install-popup-content">
        <span className="close" onClick={onClose}>&times;</span>
        <p>Install Coffy for discounts & offers!</p>
        <button onClick={handleInstallClick} className="install-button">Install Coffy  🛍️</button>
      </div>
    </div>
  );
};

export default InstallPopup;
