import React, { useState, useRef, useEffect } from 'react';
import './SearchComponent.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faArrowRight, faFilter, faMapMarkerAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import Masonry from 'react-masonry-css';
import ReactSlider from 'react-slider';
import InstallPopup from './installPopUp';
import { jwtDecode } from 'jwt-decode';

const initialQuestions = {};

const brandsList = [
  { name: "H&M", logoUrl: "https://i.pinimg.com/736x/ed/a6/46/eda6460f07dcec2a864498ab223deec9.jpg" },
  { name: "Zara", logoUrl: "https://i.pinimg.com/564x/af/84/6e/af846e8b9c2f4ecec25e6a13e0faa07e.jpg" },
  { name: "The Souled Store", logoUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXmXeaAcCLRcVh8yWtznwj6UN0AigWDEpTZg&s" },
  { name: "Bonkers Corner", logoUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTWeR1T0By47mcRRQKT1OyomeZ9TjcfKyJ5qA&s" },
  { name: "Bewakoof", logoUrl: "https://mir-s3-cdn-cf.behance.net/projects/404/23a171191784743.Y3JvcCwxNDAwLDEwOTUsMCwxNTI.jpg" },
  { name: "Fable Street", logoUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRBpbCpBoIkkniIdgK0-NEvPQTMEQDSK3_xug&s" },
  { name: "Uniqlo", logoUrl: "https://upload.wikimedia.org/wikipedia/commons/thumb/9/92/UNIQLO_logo.svg/1029px-UNIQLO_logo.svg.png" },
  { name: "Fabindia", logoUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXz_JC-NnKm3oiV6NQbgES8FUhCg51jIeXKQ&s" },
  { name: "Allen Solly", logoUrl: "https://crystalpng.com/wp-content/uploads/2023/03/allen-solly-logo.png" },
  { name: "Only", logoUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTE5_UzN6iOLFKHXcjIWNQiX99gB4gH6ceheg&s" },
  { name: "Biba", logoUrl: "https://upload.wikimedia.org/wikipedia/en/b/b2/Logo_of_Biba_Apparels.png" },
  { name: "Levis", logoUrl: "https://media.designrush.com/inspiration_images/292255/conversions/levi_logo_4_82250bd000e1-mobile.jpg" },
  { name: "Adidas", logoUrl: "https://static.vecteezy.com/system/resources/previews/019/136/412/non_2x/adidas-logo-adidas-icon-free-free-vector.jpg" },
  { name: "Nike", logoUrl: "https://cdn.iconscout.com/icon/free/png-256/free-nike-3-202655.png" },
  { name: "Forever 21", logoUrl: "https://play-lh.googleusercontent.com/KvjPFAHzgHACNxLyic2KQTHHxLEb0gQx_-lNx46XB7antXXkBiHNq67fAqOrBlIVfcZR" },
  { name: "Bershka", logoUrl: "https://vectorseek.com/wp-content/uploads/2022/02/Bershka-Logo-Vector.jpg" },
  { name: "Calvin Klein", logoUrl: "https://media.fashionnetwork.com/m/6e43/5c5a/a502/49c9/f630/4804/11fa/e616/91bd/e614/e614.png" },
  { name: "Marks & Spencer", logoUrl: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS987ar9iQCrRGFHIghWwGxrv22V1q673GByg&s" }
];

let promptExamples = [
  { prompt: "Sarees for Wedding", description: "Discover beautiful sarees for wedding occasions." },
  { prompt: "Kurtis for Office", description: "Explore elegant kurtis perfect for office wear." },
  { prompt: "Evening Gowns", description: "Find stunning evening gowns for special occasions." },
  { prompt: "Casual Tops", description: "Shop for comfortable and stylish casual tops." },
  { prompt: "Party Wear Dresses", description: "Check out trendy party wear dresses for special events." },
];

const locations = [
  "India", "United States", "United Kingdom", "Australia", "Canada"
];

const locales = [
  "en-in", "en-us", "en-gb", "en-au", "en-ca"
];


const SearchComponent = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [previousSearchQuery, setPreviousSearchQuery] = useState('');
  const [previousSearchBoxString, setPreviousSearchBoxString] = useState('');
  let [questions, setQuestions] = useState(initialQuestions);
  let [currentQuestionKey, setCurrentQuestionKey] = useState('');
  let [questionKeys, setQuestionKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resultsFetched, setResultsFetched] = useState(false);
  const [products, setProducts] = useState([]);
  let [selectedOptions, setSelectedOptions] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [selectedBrands, setSelectedBrands] = useState(new Set());
  const [selectedSizes, setSelectedSizes] = useState(new Set());
  const [selectedGenders, setSelectedGenders] = useState(new Set());
  const [priceRange, setPriceRange] = useState([0, 100000]);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const productsGridRef = useRef(null);
  const [location, setLocation] = useState('India');
  const [locale, setLocale] = useState('en-IN');
  const [showLocationPopup, setShowLocationPopup] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  let [emailUsed, setEmailUsed] = useState('');

  useEffect(() => {
    const initializeGoogleSignIn = () => {
      /* global google */
      if (window.google) {
        window.google.accounts.id.initialize({
          client_id: '639879146679-qk3ktl6ai55ojc4cg0dh27jf65mr7ls4.apps.googleusercontent.com',
          callback: handleLoginSuccess,
        });

        window.google.accounts.id.renderButton(
          document.getElementById('google-signin-button'),
          {
            theme: 'outline',
            size: 'large',
          }
        );
      }
    };

    if (window.google) {
      initializeGoogleSignIn();
    } else {
      const intervalId = setInterval(() => {
        if (window.google) {
          clearInterval(intervalId);
          initializeGoogleSignIn();
        }
      }, 100);
    }
  }, []);

  const handleLoginSuccess = (response) => {
    const userObject = jwtDecode(response.credential);
    emailUsed = userObject.email;
    console.log(emailUsed);
    setIsLoggedIn(true);
  };

  const handleScroll = () => {
    const currentScrollPos = productsGridRef.current.scrollTop;
    setIsScrollingDown(prevScrollPos < currentScrollPos && currentScrollPos > 0);
    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    const productsGrid = productsGridRef.current;
    productsGrid.addEventListener('scroll', handleScroll);
    return () => productsGrid.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleSearch = async () => {

    if (previousSearchQuery === '' && searchQuery) {
      console.log('first search');
      setSelectedOptions({});
      setSelectedGenders(new Set());
      setSelectedSizes(new Set());
      setSelectedBrands(new Set());
      setLoading(true);
      setPriceRange([0, 100000]);
      setResultsFetched(false);
    }

    if (searchQuery) {
      setProducts([]);
      updateSearchString(selectedOptions, selectedBrands, priceRange, selectedSizes, selectedGenders);
      // fetchResults(searchQuery);
      if (searchQuery !== previousSearchBoxString) {
        selectedOptions = {};
        setSelectedOptions({});
        setPreviousSearchBoxString(searchQuery);
        updateSearchString(selectedOptions, selectedBrands, priceRange, selectedSizes, selectedGenders);
        const fetchedQuestions = await fetchQuestionsFromAPI(searchQuery);
        const newQuestionKeys = Object.keys(fetchedQuestions);
        console.log(fetchedQuestions);
        questions = {};
        questionKeys = [];
        currentQuestionKey = '';
        setQuestions({});
        setQuestionKeys([]);
        setCurrentQuestionKey('');
        setQuestions(fetchedQuestions);
        setQuestionKeys(newQuestionKeys);
        setCurrentQuestionKey(newQuestionKeys[0]);
      } else {
        updateSearchString(selectedOptions, selectedBrands, priceRange, selectedSizes, selectedGenders);
      }
    }

    // setLoading(false);
  };

  const handleOptionClick = (option) => {
    const cleanedOption = option.toLowerCase().replace(/[₹\s\(\)\+]/g, '-');
    const updatedSelectedOptions = { ...selectedOptions };

    if (!updatedSelectedOptions[currentQuestionKey]) {
      updatedSelectedOptions[currentQuestionKey] = new Set();
    }

    if (updatedSelectedOptions[currentQuestionKey].has(cleanedOption)) {
      updatedSelectedOptions[currentQuestionKey].delete(cleanedOption);
    } else {
      updatedSelectedOptions[currentQuestionKey].add(cleanedOption);
    }

    setSelectedOptions(updatedSelectedOptions);
    // updateSearchString(updatedSelectedOptions, selectedBrands, priceRange, selectedSizes, selectedGenders);
  };

  const handleBrandClick = (brand) => {
    const updatedBrands = new Set(selectedBrands);
    if (updatedBrands.has(brand)) {
      updatedBrands.delete(brand);
    } else {
      updatedBrands.add(brand);
    }
    setSelectedBrands(updatedBrands);
  };

  const handleSizesClick = (brand) => {
    const updatedSizes = new Set(selectedSizes);
    if (updatedSizes.has(brand)) {
      updatedSizes.delete(brand);
    } else {
      updatedSizes.add(brand);
    }
    setSelectedSizes(updatedSizes);
  };

  const handleGendersClick = (brand) => {
    const updatedGenders = new Set(selectedGenders);
    if (updatedGenders.has(brand)) {
      updatedGenders.delete(brand);
    } else {
      updatedGenders.add(brand);
    }
    setSelectedGenders(updatedGenders);
  };

  const handlePriceChange = (values) => {
    setPriceRange(values);
  };

  const updateSearchString = (options, brands, prices, sizes, genders) => {
    const responsesString = Object.keys(options).map((key) => {
      return Array.from(options[key]).join(' ');
    }).join(' ');

    const brandsString = Array.from(brands).join(' ');
    const sizesString = Array.from(sizes).join(' ');
    const gendersString = Array.from(genders).join(' ');
    let priceString = `${prices[0]} to ${prices[1]}`;
    // if (!previousSearchQuery.includes("Rs")) { // handling the first time case
      if (prices[0] === 0 && prices[1] === 100000) {
        console.log('prices unchanged');
        priceString = '';
      }
    // }
    const updatedKeywordString = (searchQuery + ' ' + responsesString + ' ' + brandsString + ' ' + sizesString + ' ' + gendersString + ' ' + priceString).replace(/\s+/g, ' ').trim();
    console.log(`Updated Search String: ${updatedKeywordString}`);
    fetchResults(updatedKeywordString, options);
  };

  const goToNextQuestion = () => {
    const currentIndex = questionKeys.indexOf(currentQuestionKey);
    if (currentIndex < questionKeys.length - 1) {
      setCurrentQuestionKey(questionKeys[currentIndex + 1]);
    }
  };

  const goToPreviousQuestion = () => {
    const currentIndex = questionKeys.indexOf(currentQuestionKey);
    if (currentIndex > 0) {
      setCurrentQuestionKey(questionKeys[currentIndex - 1]);
    }
  };

  const finishSurvey = (finalResponses) => {
    const finalKeywords = (searchQuery + ' ' + Object.values(finalResponses).flat().join(' ')).trim();
  };

  const fetchQuestionsFromAPI = async (query) => {
    try {
      const searchPrompt = `I want to buy a clothing item ${query}. Ask me clarifying questions around this in priority in a question and options format. nothing else. I am an Indian user. Prioritise the list in order. I am aspirational, show off, price sensitive. Only answer in this format - Questions and Options and list of these in prioritised order for me. include style, material, type, features, coverage, etc. not brands or offline online stores, and other unimportant stuff. subsequent questions' options can change based on previous questions' answers. accommodate that as well. color, style, material etc are important and mandatory questions, do ask them if necessary, don't have to ask if already present in the query. do not go very technical with your questions. keep them simple, we are indians. we just want value for money. sample format for response is this - Question: {question}\nOptions: {comma separated options}. give me a list in this format, do not have even one character other than the given format. keep the format the same. do not ask super detailed and technical questions, we are indians. the question should be super short, maximum 3 or 4 words. do not ask more than 5 questions. ask the most important questions according to the query, nothing more than that. no gender or size or price range questions. the color question should be come before any other question. do not repeat questions if that parameter is give, example - do not ask for color if it is given. it's not compulsory to give 5 questions. you can give 1 or 2 questions as well. try to give 5 full questions though.`;
      const response = await axios.get(
        `https://coffy-demo.rudransh2422.workers.dev/coffy/service/get_dynamic_chat?text=${encodeURIComponent(
          'premium expensive brands ' + searchPrompt
        )}`
      );
      const data = response.data;
      return parseAPIResponse(data);
    } catch (error) {
      console.error('Error fetching questions:', error);
      return {};
    }
  };

  const parseAPIResponse = (data) => {
    const parsedQuestions = {};
    const lines = data.split('\n');
    let currentQuestionKey = '';
    let questionIndex = 1;

    lines.forEach((line) => {
      if (line.startsWith('Question:')) {
        currentQuestionKey = `question_${questionIndex++}`;
        parsedQuestions[currentQuestionKey] = {
          text: line.replace('Question:', '').trim(),
          options: []
        };
      } else if (line.startsWith('Options:')) {
        const options = line.replace('Options:', '').split(',').map((option) => option.trim());
        if (currentQuestionKey) {
          parsedQuestions[currentQuestionKey].options = options;
        }
      }
    });

    return parsedQuestions;
  };

  const fetchResults = async (keywords, updatedResponses) => {
    try {
      setLoading(true);
      console.log("psq - " + previousSearchQuery);
      console.log("nsq - " + keywords.replace(/-/g, ' '));
      if (previousSearchQuery !== keywords.replace(/-/g, ' ')) {
        promptExamples = [];
        setPreviousSearchQuery(keywords.replace(/-/g, ' '));
        console.log("psq - " + previousSearchQuery);
        const query = `fashion premium expensive brands ${keywords.replace(/-/g, ' ')} - myntra, ajio, flipkart, amazon and other big d2c brands`;
        const locationVar = location;
        const localeVar = locale;

        const url = new URL('https://coffy-search-demo.rudransh2422.workers.dev/');
        url.searchParams.append('q', query);
        url.searchParams.append('location', locationVar);
        url.searchParams.append('locale', localeVar);

        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          }
        });

        const data = await response.json();
        const organicResults = data.results[0]?.content?.results?.results?.organic || [];
        setProducts(organicResults);
        setResultsFetched(true);
        setLoading(false);
        const currentIndex = questionKeys.indexOf(currentQuestionKey);
        if (currentIndex < questionKeys.length - 1) {
          if (!showFilter) {
            setCurrentQuestionKey(questionKeys[currentIndex + 1]);
          }
        } else {
          finishSurvey(updatedResponses);
        }
      }
      setLoading(false);
    } catch (error) {
      console.error('Error fetching results:', error);
      setLoading(false);
    }
  };

  const getOptions = () => {
    if (currentQuestionKey && questions[currentQuestionKey]) {
      return questions[currentQuestionKey].options;
    }
    return [];
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  const containerRef = useRef(null);
  const [hasScrolled, setHasScrolled] = useState(false);

  useEffect(() => {
    const scrollToBottom = () => {
      if (window.innerWidth <= 768 && containerRef.current) {
        containerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        setHasScrolled(true);
      }
    };

    if (!hasScrolled) {
      setTimeout(scrollToBottom, 100);
    }
  }, [hasScrolled]);

  const breakpointColumnsObj = {
    default: 4,
    1100: 3,
    700: 2,
    500: 2
  };

  const toggleFilter = () => {
    if (showFilter) {
      setShowFilter(!showFilter);
      updateSearchString(selectedOptions, selectedBrands, priceRange, selectedSizes, selectedGenders);
    } else {
      setShowFilter(!showFilter);
    }
  };

  const toggleLocationPopup = () => {
    setShowLocationPopup(!showLocationPopup);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };

  const handleLocaleChange = (event) => {
    setLocale(event.target.value);
  };

  const saveLocationSettings = () => {
    toggleLocationPopup();
  };

  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (deferredPrompt) {
        setShowPopup(true);
      }
    }, 3000); // 3 seconds delay

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
    });

    return () => clearTimeout(timer);
  }, [deferredPrompt]);

  const handleInstallClick = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
        setDeferredPrompt(null);
        setShowPopup(false);
      });
    }
  };

  const closePopup = () => {
    setShowPopup(false);
  };

  // Utility function to clean the URL
  const cleanUrl = (url) => {
    const cleanedUrl = url.replace(/.*(http[s]?:\/\/.*)/, '$1');
    return decodeURIComponent(cleanedUrl.split('&')[0]);
  };

  return (
    <div className="container" ref={containerRef}>
      {showPopup && (
        <InstallPopup handleInstallClick={handleInstallClick} onClose={closePopup} />
      )}
      {showLocationPopup && (
      <div className="location-popup">
        <button className="close-button" onClick={toggleLocationPopup}>
            <FontAwesomeIcon icon={faTimes} />
        </button>
        <h4>Set Location</h4>
        <div className="location-input">
          <label>Location </label>
          <select value={location} onChange={handleLocationChange}>
            {locations.map((loc) => (
              <option key={loc} value={loc}>{loc}</option>
            ))}
          </select>
        </div>
        <div className="locale-input">
          <label>Locale </label>
          <select value={locale} onChange={handleLocaleChange}>
            {locales.map((loc) => (
              <option key={loc} value={loc}>{loc}</option>
            ))}
          </select>
        </div>
        <button onClick={saveLocationSettings}>Save</button>
      </div>
      )}
      {/* Google Sign-In */}
      {!isLoggedIn && (
        <div className="login-container">
          <div id="google-signin-button"></div>
        </div>
      )}
      {/* Conditional Rendering for Prompt Examples */}
      {promptExamples.length !== 0 && (
        <div className="prompt-examples">
          {/* <h2>Popular Searches</h2> */}
          <div className="examples-grid">
            {promptExamples.map((example, index) => (
              <div key={index} className="example-card" onClick={() => setSearchQuery(example.prompt.toLowerCase())}>
                <h3>{example.prompt}</h3>
                <p>{example.description}</p>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className='question-and-search'>
        <div className="search-container">
          <input
            type="text"
            placeholder="What do you want to buy?"
            value={searchQuery}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            className="search-input"
            disabled={loading}
          />
          {loading ? (
            <div className="loader"></div>
          ) : (
            <button onClick={handleSearch} className="search-button">
              <FontAwesomeIcon icon={faArrowRight} />
            </button>
          )}
        </div>
        <div className={`question-container ${isScrollingDown ? 'hidden' : ''}`}>
          {currentQuestionKey && questions[currentQuestionKey] && (
            <div className="question-block">
              <button onClick={goToPreviousQuestion} className="nav-button" disabled={questionKeys.indexOf(currentQuestionKey) === 0}>
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <div className="question-content">
                <h4>{questions[currentQuestionKey].text}</h4>
                <div className="options-row">
                  {getOptions().map((option, index) => (
                    <button
                      key={index}
                      onClick={() => handleOptionClick(option)}
                      className={`option-button ${selectedOptions[currentQuestionKey]?.has(option.toLowerCase().replace(/[₹\s\(\)\+]/g, '-')) ? 'selected' : ''}`}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              </div>
              <button onClick={goToNextQuestion} className="nav-button" disabled={questionKeys.indexOf(currentQuestionKey) === questionKeys.length - 1}>
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          )}
        </div>
      </div>
      <button className="filter-button" onClick={toggleFilter}>
        <FontAwesomeIcon icon={faFilter} />
      </button>
      <button className="location-button" onClick={toggleLocationPopup}>
        <FontAwesomeIcon icon={faMapMarkerAlt} />
      </button>
      {showFilter && (
        <div className="filter-options">
          <h4>Filter by:</h4>
          <div className="filter-row">
            <span className="filter-label">Gender:</span>
            {['Male', 'Female'].map((option, index) => (
              <button
                key={index}
                onClick={() => handleGendersClick(option)}
                className={`filter-option-button ${selectedGenders.has(option) ? 'selected' : ''}`}
              >
                {option}
              </button>
            ))}
          </div>
          <div className="filter-row">
            <span className="filter-label">Size:</span>
            {['XS', 'S', 'M', 'L', 'XL', 'XXL'].map((option, index) => (
              <button
                key={index}
                onClick={() => handleSizesClick(option)}
                className={`filter-option-button ${selectedSizes.has(option) ? 'selected' : ''}`}
              >
                {option}
              </button>
            ))}
          </div>
          <div className="filter-row">
            <span className="filter-label">Price:</span>
            <ReactSlider
              className="horizontal-slider"
              thumbClassName="example-thumb"
              trackClassName="example-track"
              min={0}
              max={100000}
              step={500}
              value={priceRange}
              onChange={(values) => handlePriceChange(values)}
            />
            <span>{priceRange[0]} - {priceRange[1]}</span>
          </div>
          <div className="filter-row">
            <span className="filter-label">Brands:</span>
            {brandsList.map((brand) => (
              <div
                key={brand.name}
                className={`brand-logo ${selectedBrands.has(brand.name) ? 'selected' : ''}`}
                onClick={() => handleBrandClick(brand.name)}
              >
                <img src={brand.logoUrl} alt={brand.name} />
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="products-container" ref={productsGridRef} style={{ display: promptExamples.length !== 0 ? 'none' : 'flex' }}>
        <Masonry
          breakpointCols={breakpointColumnsObj}
          className="products-grid"
          columnClassName="products-grid-column"
        >
          {products.map((product, index) => (
            <div key={index} className="product-card" onClick={() => window.open(cleanUrl(product.merchant.url), '_blank')}>
              <img src={product.thumbnail} alt={product.title.split(' ')[0]} className="product-image" />
              <div className="product-info">
                <span className="product-brand">{product.title.split(' ')[0]}</span>
                <span className="product-price">{product.price_str}</span>
                <span className="product-source">{product.source}</span>
              </div>
            </div>
          ))}
        </Masonry>
      </div>
    </div>
  );
};

export default SearchComponent;
