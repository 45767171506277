import React from 'react';
import './privacypolicy.css';

function PrivacyPolicy() {
  return (
    <div className="container-pp">
      <header className="header">
      <h1>Privacy Policy</h1>
      <p>coffy is strongly committed to your right to privacy. This section covers coffy's privacy policy that highlights how we handle personally identifiable information that coffy collects when you are on our Website or App. Please read this privacy policy carefully. All information covered by coffy's privacy policy will continue to be protected under coffy's privacy policy irrespective of any change in your membership status in the future. By using the coffy Website or App, you accept all practices described in this privacy policy.</p>
      <br></br>
      <h2>Why do we collect personal information?</h2>
      <p>In this Privacy Policy, your "Personal Data" means information or pieces of information that could allow you to be identified. We process your personal data for us to be able to carry out our contractual commitments towards you. In addition we process Personal Data to comply with legal obligations. Sometimes we need your consent to process Personal Data, such as for sending you newsletters and promotions. Before you can become a user we also ask for your explicit consent so that we can:</p>
      <section className="policy-list">
        <ul>
          <li>Process your profile information - Primary Account Information</li>
          <li>Offer you services mentioned in this Privacy Policy that require an act of consent from you</li>
        </ul>
      </section>
      <br></br>
      <h2>Which personal information do we process?</h2>
      <p>You will be asked to register before you can use coffy. In the process, you will be asked for your email or any other Primary Account information. We will process your IP address, login information i.e., your email (via google signin), customer service history via email, time zone settings, and device ID</p>
      <br></br>
      <h2>How do we use your personal data?</h2>
      <p>We will only disclose your Personal Data to third parties if there is a legal obligation to do so by a statutory authority from the appropriate jurisdiction. We do not sell your Personal Data to third parties. Your email will be your identity on our platform.</p>
      <section className="policy-list">
        <ul>
          <li>Your email or any other Primary Account information <p>We do not share your email with third parties. Your email will be used to: inform you of your membership status; notify you other relevant activity regarding Your Account; engage you with the application through newsletters and for troubleshooting.</p></li>
          <li>Your customer service history <p>If you contact our customer service team, we keep a record of your correspondence with us, in order to optimize our service for you.</p></li>
          <li>Non-personal data <p>We may also process non-personal data whenever you use our website or app. This data may include your mobile device and technical information about your means to connect to our Websites (such as your operating system and platform and the utilized internet service providers).</p></li>
          <li>Push notifications from coffy <p>If you have given us explicit permission, we will send you push notifications via our App to notify you of new messages you have received and other activity with regard to Your Account.</p></li>
        </ul>
      </section>
      <br></br>
      <h2>Inviting friends</h2>
      <p>If you refer a friend to coffy, you will send an email message, Whatsapp message, or SMS. By choosing to invite your friend to join coffy you confirm that your friend is happy to receive the invitation. You also hereby agree to indemnify and hold coffy harmless from all claims arising out of your messages to any person through coffy. You must and will not use our invite friends feature to send spam.</p>
      <br></br>
      <h2>How do we protect your personal data?</h2>
      <p>Your primary account information may pass through cloudflare workers for secure calls. After every logout, app deletion or app update, we re-authenticate email only if you open the app again. We try our best to ensure that your Personal Data is protected and secure from unauthorized or unlawful access, alteration, disclosure, use or destruction. We encrypt our services and our employees only have access to your data on a need-to-know basis. Unfortunately, the transmission of information via the internet is not always completely secure. Although we take all reasonable measures, we cannot guarantee the security of the data you transmit to our Website or App; any transmission is at your own risk.</p>
      <br></br>
      <h2>How long do we retain your personal data?</h2>
      <p>Your email data persists in our databases until you explicitly request removal, which may take 4-8 weeks to process and acknowledge. Your Personal Data is retained no longer than strictly necessary or as long as allowed by law, whichever is shorter. In the event that you have not logged in to your profile for five years, your Personal Data may be erased from our records. Of course, you can always contact our support team through your email address with a request to delete your data from our records.</p>
      <br></br>
      <h2>Changes to this privacy policy</h2>
      <p>We are constantly looking for new ways to improve coffy and we may unilaterally update this Privacy Policy from time to time. When there is a significant change in this Privacy Policy, you will see a pop up in the app which you will have to accept before you can continue to use coffy.</p>
      <br></br>
      <h2>Storage and transfer of your personal data</h2>
      <p>Your primary account information may pass through cloudflare workers for secure calls and your personal data may be hosted by our storage partners in servers located in countries other than your country of residence. To protect loss of data due to natural disasters and other such unforeseeable circumstances, we store your details as backup for a safety retention window of upto three years following account deletion. During this period, account information will be retained although the account will of course not be visible on coffy anymore.</p>
      <br class="small-break"></br>
      <p>When you log in again, your email will be re-authenticated. For internal use, we may store generic, non-personal data regarding your activity as a user (number of people invited, number of logins, etc).</p>
      <br class="small-break"></br>
      <p>When registering, the Member expressly consents to his/her data being transmitted to coffy service providers and subcontractors outside of the Member's country of residence, to let coffy run statistical studies, support users, and if need be, host data processed by the Application. coffy guarantees that the transfers are made under conditions ensuring the confidentiality and security of the data and providing an adequate level of protection in compliance with regulations.</p>
      <br></br>
      <h2>We may disclose your personal information</h2>
      <section className="policy-list">
        <ul>
          <li>in the event that we sell or buy any business or assets, in which case we may disclose your personal information to the prospective seller or buyer of such business or assets</li>
          <li>to identify, contact or bring legal action against you if you are violating our terms of use or other rules or if we believe you are causing injury or interference with the rights of users, the general public or coffy</li>
          <li>if we or substantially all of our assets are acquired by a third party, in which case personal information held by us will be a part of the transferred assets</li>
          <li>if we are under a duty to disclose or share your personal information in order to comply with any legal obligation, or in order to enforce or apply our terms and other agreements or to protect our rights, property, or safety of our customers, or others</li>
        </ul>
      </section>
      <h2>Who are we?</h2>
      <p>coffy, the accompanying website and app are owned by coffy, Bengaluru, Karnataka, India - 560103</p>
      <p>We reserve the right, at our discretion, to make changes to the terms of use and privacy policy at any time. The most updated version is available on our App and Website at all times. Please check the terms of use and privacy policy periodically for changes as all modifications are effective upon publication. Continued use of the coffy app and website after revision indicates the user's acceptance of the terms of use and privacy policy.</p>
      <p>In case you come across suspicious activity, immediately notify us at <h2>support@thecoffyshop.com</h2></p>
      <br></br>
      </header>
    </div>
  );
};

export default PrivacyPolicy;
