import React from 'react';
import './App.css';
import logo from './logo.png';

function Home() {
  const handleJoinClick = () => {
    window.open('https://play.google.com/store/apps/details?id=com.kixxcorner', '_blank');
  };

  const handleSellClick = () => {
    window.open('https://apps.apple.com/in/app/coffy-discover-fashion/id6499106907', '_blank');
  };

  return (
    <div className="page-wrapper">
      <div className="gradient-background"></div>
      <div className="app-container">
        <div className="card">
          <img src={logo} alt="Coffy logo" className="logo" />
          <h2 className="store-name">The Fashion Store</h2>
          <p className="description">Amazing <em>fashion</em> from your<br />favourite <em>Instagram brands</em></p>
          <div className="button-container">
            <button className="join-button" onClick={handleJoinClick}>
              <i className="fab fa-google-play"></i> Android App
            </button>
            <button className="sell-button" onClick={handleSellClick}>
              <i className="fab fa-apple"></i> iOS App
            </button>
          </div>
          <div className="social-links">
            <a href="https://chat.whatsapp.com/LjI3JR4OsxX20jLTOcDWHh" target="_blank" rel="noopener noreferrer" className="whatsapp-link">
              <i className="fab fa-whatsapp"></i> Join our WhatsApp group
            </a>
            <div className="additional-social-links">
              <a href="mailto:support@thecoffyshop.com" className="email-link">
                <i className="far fa-envelope"></i>
              </a>
              <a href="https://www.instagram.com/thecoffyshop" target="_blank" rel="noopener noreferrer" className="instagram-link">
                <i className="fab fa-instagram"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;