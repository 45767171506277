import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter as Router, Routes, Route, Link, Navigate } from 'react-router-dom';
import Home from './Home';
import PrivacyPolicy from './PrivacyPolicy';
import SellWithUs from './SellWithUs';
import reportWebVitals from './reportWebVitals';
import SearchComponent from './searchComponent';

// ... (rest of your imports and service worker code)

const DetectDeviceAndRedirect = () => {
  useEffect(() => {
    const userAgent = navigator.userAgent.toLowerCase();
    if (/android/i.test(userAgent)) {
      window.location.href = 'https://play.google.com/store/apps/details?id=com.kixxcorner'; // Replace 'link1' with your Android app link
    } else if (/iphone|ipad|ipod/i.test(userAgent)) {
      window.location.href = 'https://apps.apple.com/in/app/coffy-discover-fashion/id6499106907'; // Replace 'link2' with your iOS app link
    } else {
      console.log('not android or ios');
    }
  }, []);

  return <Home />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<DetectDeviceAndRedirect />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/sell-with-us" element={<SellWithUs />} />
          <Route path="/search" element={<SearchComponent />} />
        </Routes>
        {/* <footer>
          <Link to="/privacy-policy" className="privacy-policy">Privacy Policy</Link>
        </footer> */}
      </div>
    </Router>
  </React.StrictMode>
);

reportWebVitals();