import React from 'react';
import './sellwithus.css';
import logo from './logo.png'; // Ensure you have the logo.png in your src folder


      function SellWithUs() {
  return (
    <div className="sell-with-us-container">
      <section className="why-join-coffy">
        <h2>Why Join Coffy</h2>
        <ul>
          <li>ZERO Listing Fees</li>
          <li>ZERO Commissions</li>
          <li>24/7 Call Support</li>
          <li>ZERO Competition with other brands for visibility</li>
          <li>Explicit YES or NO feedback for your products</li>
        </ul>
      </section>

      <section className="steps-to-register">
        <h2>Steps to Register</h2>
        <ol>
        <li><a href="https://forms.gle/TSKZ61SVdAG3z3FX8" target="_blank" rel="noopener noreferrer">Register using this form</a></li>
          <li>Fill in the required details - Bank Account Details, Product Catalog CSV, etc.</li>
          <li>Our Team will reach out within 24 hours</li>
        </ol>
      </section>

      <section className="faq-section">
        <h2>FAQs</h2>
        <div className="faq-item">
          <h3>How do I register as a brand?</h3>
          <p><a href="https://forms.gle/TSKZ61SVdAG3z3FX8" target="_blank" rel="noopener noreferrer">Register using this form</a> and submit the required details.</p>
        </div>
        <div className="faq-item">
          <h3>What is different from Amazon or Myntra?</h3>
          <p>Coffy is an AI-based marketplace. You do not compete with brands to show up on users' screen. If user likes your products, you repeatedly show up on her screen. Brands cannot pay and occupy screen space like they do on Amazon or Myntra.</p>
        </div>
        <div className="faq-item">
          <h3>What documents are required for registration?</h3>
          <p>You will need to submit your PAN Card, GSTIN Certificate, and Bank Account Details.</p>
        </div>
        <div className="faq-item">
          <h3>How long does the approval process take?</h3>
          <p>After filling in the form, our team will reach out and within 24 hours your products will be listed.</p>
        </div>
        <div className="faq-item">
          <h3>Does Coffy handle shipping or tracking or returns?</h3>
          <p>No, we do not handle post-order process. We need brands to update us on the order.</p>
        </div>
        <div className="faq-item">
          <h3>What are the commission rates?</h3>
          <p>Our commission rates are ZERO.</p>
        </div>
        <div className="faq-item">
          <h3>What is the payment cycle?</h3>
          <p>15 day payment settlement cycle from the date of delivery of products to customers.</p>
        </div>
        <div className="faq-item">
          <h3>Does Coffy provide its own order management system?</h3>
          <p>No, we do not have our own OMS. We have Real-Time Dashboard, API & Email Integrations to update brands about an order.</p>
        </div>
        <div className="faq-item">
          <h3>Do I have to pay a registration charge to start selling on Coffy?</h3>
          <p>No. You can get started on Coffy without a registration charge.</p>
        </div>
        <div className="faq-item">
          <h3>Got more questions?</h3>
          <p><a href="mailto:partnerships@thecoffyshop.com" target="_blank" rel="noopener noreferrer">Email: partnerships@thecoffyshop.com</a></p>
        </div>
      </section>
      <img src={logo} alt="Coffy logo" className="logo" />
    </div>
  );
}

export default SellWithUs;